import React from 'react';
import Button from './Button';
import heroImage from '../assets/images/hero.png';

const Hero = () => {
    return (
        <section className="bg-gray-100 py-20">
            <div className="container mx-auto px-6 flex flex-col md:flex-row items-center justify-between">
                <div className="w-full md:w-1/2">
                    <h1 className="text-4xl font-bold text-gray-800 mb-4">
                        Protect Your Privacy with Smart Filtering
                    </h1>
                    <p className="text-gray-600 mb-6">
                        Our app filters scam and spam messages locally on your iOS device, ensuring your privacy and security without compromising performance.
                    </p>
                    <Button text ="Get Started" href="#features" />
                </div>
                <div className="w-full md:w-1/2 mt-10 md:mt-0 flex justify-end">
                    <img src={heroImage} alt="App Screeshot" className="rounded-lg w-2/3 h-auto" />
                </div>
            </div>
        </section>
    );
};

export default Hero;