import React from 'react';

const Button = ({ text, onClick, type = 'button', href }) => {
    const baseStyle = 'px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition';

    if (href) {
        return (
            <a href={href} className={baseStyle}>
                {text}
            </a>
        );
    }

    return (
        <button type={type} onClick={onClick} className={baseStyle}>
            {text}
        </button>
    );
};

export default Button;