import React from 'react';
import Button from './Button';

const Header = () => {
    return (
        <header className="bg-white shadow">
            <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                <div className="text-xl font-bold text-gray-800">Message Defender</div>
                <Button text="Download on the App Store"/>
            </div>
        </header>
    );
};

export default Header;
