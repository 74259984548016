import React from 'react';
import Feature from './Feature';
import { ShieldCheckIcon, FunnelIcon, CogIcon } from '@heroicons/react/24/outline';

const Features = () => {
    const featureList = [
        {
            title: 'Privacy First',
            description: 'No data is sent or stored externally unless you choose to report suspicious messages.',
            icon: <ShieldCheckIcon className="h-6 w-6" />,
        },
        {
            title: 'Advanced AI',
            description: 'Filters scam and spam messages locally on your iOS device.',
            icon: <FunnelIcon className="h-6 w-6" />,
        },
        {
            title: 'Minimalist Design',
            description: 'No complex setup required; AI models are updated monthly.',
            icon: <CogIcon className="h-6 w-6" />,
        },
    ];

    return (
        <section id="features" className="py-20">
            <div className="container mx-auto px-6">
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">Features</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {featureList.map((feature, index) => (
                        <Feature 
                            key={index} 
                            title={feature.title} 
                            description={feature.description} 
                            icon={feature.icon} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Features;