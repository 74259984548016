import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-white py-6 border-t">
            <div className="container mx-auto px-6 text-center">
                <p className="text-gray-600">&copy; {new Date().getFullYear()} MagiLand. All rights reserved.</p> 
            </div>
        </footer>
    )
};

export default Footer;