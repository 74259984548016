import React from 'react';

const Feature = ({ title, description, icon }) => {
    return (
        <div className="flex items-start space-x-4">
            <div className="text-blue-600">
                {icon}
            </div>
            <div>
                <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
                <p className="text-gray-600">{description}</p>
            </div>
        </div>
    );
};

export default Feature;